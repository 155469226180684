@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  line-height: 1.625;
}

* {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.625;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* Fonts */
.font-nav {
  font-size: 1vw;
}

.heading-one {
  font-size: 5vw;
}

.heading-two {
  font-size: 4.1vw;
}

/* Burger */

.menu__icon {
  height: 32px;
  width: 43px;
  position: absolute;
  top: 50%;
  display: inline-block;
  vertical-align: middle;
  z-index: 20;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu__icon span {
  display: block;
  background: #fafafa;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
}

.menu__icon:before,
.menu__icon:after {
  content: "";
  display: block;
  background: #fafafa;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.menu__icon:before {
  top: 2px;
  margin-top: -2px;
}

.menu__icon:after {
  bottom: 2px;
  margin-bottom: -2px;
}

.menu_shown span {
  background: transparent;
}

.menu_shown:before {
  top: 50%;
  transform: rotate(45deg);
}

.menu_shown:after {
  bottom: 50%;
  transform: rotate(-45deg);
}

/* Borders */
.custom-hover-rounded {
  border: 1px solid;
  /* border-color: transparent; */
  border-radius: 50%;
  /* background-color: transparent; */
  transition: border-radius 300ms ease-in-out;
}
.custom-hover-rounded:hover {
  border: 2px solid;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-radius: 0;
  /* background-color: transparent; */
}


/* Contact */

.contact-input {
  background-color: rgba(0, 0, 0, 0.48);
  position: relative;
}

.contact-input > input,
.contact-input > textarea {
  color: #fafafa;
  outline: none;
  border: none;
  font-weight: bolder;
  position: relative;
  resize: none;
  background-color: transparent;
  position: absolute;
  width: 100%;
  margin: 10px;
  top: 100%;
  left: 0;
  height: 60px;
  transition: all 300ms ease-in-out;
  transform: translateY(-60px);
  padding: 5px;
}

.contact-input > input:focus,
.contact-input > textarea:focus {
  top: 0;
  transform: translateY(0px);
}

.contact-input > input::placeholder,
.contact-input > textarea::placeholder {
  color: #FFF;
  text-transform: capitalize;
}

.darker {
  background-color: rgba(0, 0, 0, 0.70);
  transition: 0.2s;
}
.darker:hover{
  background-color: rgba(0, 0, 0, 0.65);
}

/* menu */
.glass {
  background: rgba(255, 255, 255, 0.33);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.2px);
  -webkit-backdrop-filter: blur(10.2px);
  border: 1px solid rgba(255, 255, 255, 0.11);
}

.glassHeader {
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
}

@media (max-width: 1199px) {
  .glassHeader {
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
  
}

/* fixes */
.hvr-sweep-to-top:before {
  background-color: #fafafa !important;
}

.hvr-sweep-to-bottom:before {
  background-color: #fafafa !important;
}

.hvr-sweep-to-right:before {
  background-color: #fafafa !important;
}

@media (max-width: 1199px) {
  .hvr-sweep-to-top:before {
    background-color: transparent !important;
  }
  
  .hvr-sweep-to-bottom:before {
    background-color: transparent !important;
  }
  
  .hvr-sweep-to-right:before {
    background-color: transparent !important;
  }  
}

/* Work Text */
.workText-container {
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .workText-container:hover,  .workText-container:hover * {
    color: black !important;
  }
}

.workText-container a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* .burger-menu:hover .line {
  background-color: black !important;
} */

@media (hover: hover) and (pointer: fine) {
  .burger-menu:hover .line {
    background-color: black !important;
  }
}

.top-navigation {
  min-width: 175px;
  margin-right: 1px;
}

.top-navigation:hover, .top-navigation:hover * {
  color: black !important;
}

.top-navigation > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.workText {
  font-family: 'Raleway', sans-serif;
  writing-mode: vertical-rl;
  font-size: 30px;
}

.font-rale {
  font-family: 'Raleway', sans-serif;
}

.font-openSans {
  font-family: "Open Sans", sans-serif;
}

.cust-width {
  width: 110%;
}


.class_100vh {
  height: 100vh;
}
.class_61vh {
  height: 61vh;
}


@media only screen and (max-width: 1000px) {
  .section0,
  .section1,
  .section2,
  .section3,
  .section4 {
    height: unset !important;
    padding-top: 20vh;
  }
  .section4 {
    padding-bottom: 20vh;
  }
}



.backgroundImage {
  /* background-image: url('../public//images/home-welcome.jpg'); */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-position: center top;  */
  background-size: 10% !important; 
  /* width: 100%; */
  /* background-repeat: no-repeat; */
}

/* @media only screen and (max-width: 1200px) {
  .backgroundImage {
    height: 70vh;
  }

  .mid-line {
    height: 70vh !important;
  }
} */

/* Fonts */
/*.ab-header {*/
/*  font-size: 6.5vw  !important;*/
/*  font-weight: 800;*/
/*}*/

/*.ab-sub-header {*/
/*  font-size: 4vw  !important;*/
/*  font-weight: 800;*/
/*}*/

/*h1 {*/
/*  font-size: 5rem !important;*/
/*  font-weight: 600;*/
/*}*/

/*.bigger-h1 {*/
/*  font-size: 4vw !important;*/
/*}*/

/*h2 {*/
/*  font-size: 1.3vw !important;*/
/*}*/

/*h3 {*/
/*  font-size: clamp(10px, 20px, 60px) !important;*/
/*}*/

/*h4 {*/
/*  font-size: clamp(8px, 17px, 50px) !important;*/
/*}*/

/*h5 {*/
/*  font-size: clamp(6px, 15px, 40px) !important;*/
/*}*/

/*article {*/
/*  font-size: 1.8vw !important;*/
/*  font-weight: normal;*/
/*  line-height: 1.6;*/
/*}*/

/* pp-nav */

#pp-nav {
  display: none;
}


/* Icons */

.icon {
  width: 35px;
  height: 40px;
  max-width: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  cursor: pointer;
}

.icon > .line {
  width: 100%;
  height: 3px;
  transition: all 300ms ease-in-out;
}

.opened > .line {
  position: absolute;
}

.opened > .top {
  transform: rotate(45deg);
}

.opened > .mid {
  opacity: 0;
}

.opened > .bot {
  transform: rotate(-45deg);
}


/* Images */
.back-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 0;
  margin: 0;
}

.image-box {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

/* Loader */
.loader-content {
  position: fixed;
  z-index: 9999;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /*animation-name: disapear;*/
  animation-delay: 5000ms;
  animation-fill-mode: forwards;
}

.loader-content .starter .line {
  background-color: #7F7F7F;
  animation-fill-mode: ease-in;
  animation-duration: 900ms;
  animation-direction: forwards;
  position: absolute;
}

.loader-content .starter {
  animation-name: disapear;
  animation-delay: 1400ms;
  position: fixed;
  width: 100%;
  height: 100%;
}

.loader-content .starter .right {
  width: 2px;
  height: 100%;
  transform: scaleX(0);
  right: 10%;
  animation-name: fillSides;
  animation-delay: 0;
  bottom: 0px;
}

.loader-content .starter .top {
  width: 100%;
  transform: scaleY(0);
  height: 2px;
  top: 15%;
  animation-name: fillHights;
  animation-delay: 450ms;
  right: 0px;
}

.loader-content .starter .left {
  height: 100%;
  transform: scaleX(0);
  width: 2px;
  left: 10%;
  animation-name: fillSides;
  animation-delay: 900ms;
  top: 0px;
}

.loader-content .starter .bottom {
  width: 100%;
  transform: scaleY(0);
  height: 2px;
  bottom: 15%;
  animation-name: fillHights;
  animation-delay: 1350ms;
  animation-fill-mode: none;
  left: 0px;
}


/* final move */

.loader-content .final .line {
  background-color: #7F7F7F;
  animation-delay: 2500ms;
  animation-duration: 1s;
  position: absolute;
  animation-fill-mode: ease-in-out;
  animation-iteration-count:2;
  animation-direction: alternate;
}

.loader-content .final {
  /* opacity: 0; */
  /* animation-name: setVisible; */
  animation-delay: 500ms;
  position: fixed;
  width: 100%;
  height: 100%;
}

.loader-content .final .right {
  transform: scaleX(0);
  width: 2px;
  right: 10%;
  height: 100%;
  animation-name: fillSides;
  bottom: 0px;
}

.loader-content .final .top {
  transform: scaleY(0);
  width: 100%;
  height: 2px;
  top: 15%;
  animation-name: fillHights;
  right: 0px;
}

.loader-content .final .left {
  transform: scaleX(0);
  width: 2px;
  left: 10%;
  height: 100%;
  animation-name: fillSides;
  top: 0px;
}

.loader-content .final .bottom {
  transform: scaleY(0);
  height: 2px;
  width: 100%;
  bottom: 15%;
  animation-name: fillHights;
  left: 0px;
}


/* .side {
  animation-name: fillSidesFinal;
  animation-delay: 2400ms;
  animation-duration: 500ms;
  animation-timing-function: linear;
}

.side_r {
  animation-name: fillSidesFinal_r;
  animation-delay: 2400ms;
  animation-duration: 500ms;
  animation-timing-function: linear;
} */

#Rectangle_1 {
  /*animation-name: fillRectangle;*/
  animation-delay: 3000ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  stroke: #000;
}

#Rectangle_2 {
  /*animation-name: fillRectangle_2;*/
  animation-delay: 2000ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  stroke: #000;
}

#Rectangle_3 {
  /*animation-name: fillRectangle;*/
  animation-delay: 1000ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  stroke: #000;
}

@keyframes fillRectangle {
  from {
    fill: #fff;
    height: 0;
  }
  to {
    fill: #000;
    height: 45px;
  }
}

@keyframes fillRectangle_2 {
  from {
    fill: #fff;
    height: 0;
  }
  to {
    fill: #000;
    height: 26px;
  }
}

@keyframes fillSides {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes fillHights {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes disapear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes setVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    pointer-events: none;
  }
}


.hoverableMenu {
  text-wrap: nowrap;
  transition: all 300ms ease;
}

.hoverableMenu:hover {
  transform: translateX(50px);
}

.company {
  width: 5vw;
  height: 5vw;
  background: #000000;
}

.smaller-image-holder {
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  /* background-position: center; */
  background-size: cover;
}


/*@media only screen and (max-width: 600px) {*/
/*  a {*/
/*    font-size: 2vw !important;*/
/*  }*/
/*  .ab-header {*/
/*    font-size: 7.5vw  !important;*/
/*    font-weight: 800;*/
/*  }*/

/*  .ab-sub-header {*/
/*    font-size: 5vw  !important;*/
/*    font-weight: 800;*/
/*  }*/

/*  h1 {*/
/*    font-size: 4vw !important;*/
/*    font-weight: 600;*/
/*  }*/

/*  .bigger-h1 {*/
/*    font-size: 5vw !important;*/
/*  }*/

/*  h2 {*/
/*    font-size: 2.3vw !important;*/
/*  }*/

/*  article {*/
/*    font-size: 2.8vw !important;*/
/*    font-weight: normal;*/
/*    line-height: 1.6;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 1820px) {*/
/*  a {*/
/*    font-size: 1.5rem;*/
/*  }*/
/*  .ab-header {*/
/*    font-size: 4.5rem  !important;*/
/*    font-weight: 600;*/
/*  }*/

/*  .ab-sub-header {*/
/*    font-size: 3.5rem  !important;*/
/*    font-weight: 800;*/
/*  }*/

/*  h1 {*/
/*    font-size: 4rem !important;*/
/*    font-weight: 600;*/
/*  }*/

/*  .bigger-h1 {*/
/*    font-size: 4.5rem !important;*/
/*  }*/

/*  h2 {*/
/*    font-size: 2.5rem !important;*/
/*  }*/

/*  article {*/
/*    font-size: 1.4rem !important;*/
/*    font-weight: normal;*/
/*    line-height: 1.6;*/
/*  }*/
/*}*/


/*@media only screen and (min-width: 1940px) {*/
/*  a {*/
/*    font-size: 1.8rem;*/
/*  }*/
/*  .ab-header {*/
/*    font-size: 4.9rem  !important;*/
/*    font-weight: 600;*/
/*  }*/

/*  .ab-sub-header {*/
/*    font-size: 3.5rem  !important;*/
/*    font-weight: 800;*/
/*  }*/

/*  h1 {*/
/*    font-size: 4rem !important;*/
/*    font-weight: 600;*/
/*  }*/

/*  .bigger-h1 {*/
/*    font-size: 4.5rem !important;*/
/*  }*/

/*  h2 {*/
/*    font-size: 2.5rem !important;*/
/*  }*/

/*  article {*/
/*    font-size: 1.4rem !important;*/
/*    font-weight: normal;*/
/*    line-height: 1.6;*/
/*  }*/
/*}*/

.top-thin-line {
  height: 14%;
}
.bottom-thin-line {
  height: 14%;
}

.mid-line {
  height: calc(100% - 28%);
}

.arrow-icon {
  width: 14vh;
  height: 13.9vh;
}

@media only screen and (min-height: 1450px) {
  .arrow-icon {
    height: 13.8vh;
  }
}

.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


@media only screen and (max-width: 600px) {
  .bottom-thin-line {
    height: 60px;
  }

  .main-logo svg {
    transform: scale(0.8);
  }
  .arrow-icon {
    width: 100px;
    height: 100px;
  }

  .mobile-line {
    width: 1px;
    opacity: 0.5;
  }

  .mobile-right {
    width: 1px;
    opacity: 0.5;
  }

}
@media only screen and (max-height: 550px) {
  .main-logo svg {
    height: 10vh;
  }
  
  .smaller-showed-reversed {
    height: 60px !important;
  }
}


.h-screen {
  height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
}

#mask {
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.7;
}

.team-header {
  top: 14%;
  left: 15rem;
  color: #FFFFFF;
  /* opacity: 0.8; */
  /* letter-spacing: 8px; */
}

.arrow-icon:hover svg {
  fill: #000 !important;
}


body {
  min-height: 100vh;
  min-height: stretch;
  min-height: -webkit-fill-available;
}
html {
  height: stretch;
  height: -webkit-fill-available;
}

.home-logo a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}