@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 1.5vw;
}

* {
  box-sizing: border-box;
}


/* Fonts */
.font-nav {
  font-size: 1vw;
}

.heading-one {
  font-size: 5vw;
}

.heading-two {
  font-size: 4.1vw;
}

/* Burger */

.menu__icon {
  height: 32px;
  width: 43px;
  position: absolute;
  top: 50%;
  display: inline-block;
  vertical-align: middle;
  z-index: 20;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu__icon span {
  display: block;
  background: #fafafa;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
}

.menu__icon:before,
.menu__icon:after {
  content: "";
  display: block;
  background: #fafafa;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.menu__icon:before {
  top: 2px;
  margin-top: -2px;
}

.menu__icon:after {
  bottom: 2px;
  margin-bottom: -2px;
}

.menu_shown span {
  background: transparent;
}

.menu_shown:before {
  top: 50%;
  transform: rotate(45deg);
}

.menu_shown:after {
  bottom: 50%;
  transform: rotate(-45deg);
}

/* Contact */

.contact-input {
  background-color: rgba(0, 0, 0, 0.48);
  position: relative;
  min-height: 35px;
}

.contact-input > input,
.contact-input > textarea {
  color: #fafafa;
  outline: none;
  border: none;
  font-weight: bolder;
  position: relative;
  font-size: 1.22rem;
  resize: none;
  background-color: transparent;
  position: absolute;
  width: 97%;
  margin: 10px;
  top: 100%;
  left: 0;
  height: 60px;
  transition: all 300ms ease-in-out;
  transform: translateY(-60px);
  padding: 5px;
}

.contact-input > textarea {
  top: 85%;
  height: 120px;
  line-height: 42px;
}

.contact-input > input:focus,
.contact-input > textarea:focus {
  top: 0;
  transform: translateY(0px);
}

.contact-input > input::placeholder,
.contact-input > textarea::placeholder {
  color: #FFF;
  text-transform: capitalize;
}

.darker {
  background-color: rgba(0, 0, 0, 0.70);
  transition: 0.2s;
}
.darker:hover{
  background-color: rgba(0, 0, 0, 0.65);
}

/* menu */
.glass {
  background: rgba(255, 255, 255, 0.33);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.2px);
  -webkit-backdrop-filter: blur(10.2px);
  border: 1px solid rgba(255, 255, 255, 0.11);
}

/* fixes */
.hvr-sweep-to-top:before {
  background-color: #fafafa !important;
}

.hvr-sweep-to-bottom:before {
  background-color: #fafafa !important;
}

.hvr-sweep-to-right:before {
  background-color: #fafafa !important;
}

@media (max-width: 1199px) {
  .hvr-sweep-to-top:before {
    background-color: transparent !important;
  }
  
  .hvr-sweep-to-bottom:before {
    background-color: transparent !important;
  }
  
  .hvr-sweep-to-right:before {
    background-color: transparent !important;
  }  
}

/* Work Text */
/* .workText {
  transform: rotate(90deg);
} */



/* pp-nav */

#pp-nav {
  display: none;
}


/* Icons */

.icon {
  width: 35px;
  height: 40px;
  max-width: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  cursor: pointer;
}

.icon > .line {
  width: 100%;
  height: 3px;
  transition: all 300ms ease-in-out;
}

.opened > .line {
  position: absolute;
}

.opened > .top {
  transform: rotate(45deg);
}

.opened > .mid {
  opacity: 0;
}

.opened > .bot {
  transform: rotate(-45deg);
}


/* Images */
.back-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 0;
  margin: 0;
}

.image-box {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
}

.hoverableMenu {
  text-wrap: nowrap;
  transition: all 300ms ease;
}

.hoverableMenu:hover {
  transform: translateX(50px);
}

.companies {
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  overflow: auto;
  /* gap: 100px 200px; */
  gap: 3px 40px;
  /* margin-top: 3vh; */
  justify-content: center;
  align-items: center;
}

.companies img {
  cursor: pointer;
  margin: 20px 10px 0;
  max-height: 100px;
  /* margin-top: 20px */
  /* width: 150px; */
}

/* .companies > img:first-child {
  margin-left: 0px;
}

.companies > img:last-child {
  margin-right: 0px;
} */

.company {
  width: 5vw;
  height: 5vw;
  background: #000000;
}

.smaller-image-holder {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.custom-line {
  max-width: 0;
  width: 0;
  position: absolute;
  height: 2px;
  left: 30%;
  bottom: -10px;
  background: #fff;
  transition: all 500ms ease;
}

.custom-text a:hover .custom-line {
  max-width: 225px;
  width: 40%;
}

.smaller-showed {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}
.smaller-hidden {
  display: flex;
}

@media only screen and (max-width: 720px) {
  .smaller-showed {
    display: flex;
  }
  .smaller-hidden {
    display: none;
  }
}

.smaller-showed-reversed {
  display: none;
  position: fixed;
  left: 0;
  height: 14%;
  justify-content: center;
  align-items: center;
  font-size: 1.6vw;
  background-color: #DFDFDF;
  bottom: 0;
}
.smaller-hidden-reversed {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .contact-input > textarea {
    font-size: 1rem;
  }

  .contact-input > textarea {
    width: 97%;
    height: 75px;
    top: 88%;
    line-height: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .smaller-showed-reversed {
    display: flex;
  }

  .smaller-hidden-reversed {
    display: none;
  }

  .resp-box {
    width: 100% !important;
    padding: 20px;
  }
}

.development-text {
  /* Prefix required. Even Firefox only supports the -webkit- prefix */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}

.development-text > * {
  font-family: sans-serif !important;
  color: transparent;
}

@media only screen and (max-width: 720px) {
  .development-text > * {
    font-size: 36px !important;
  }
  .main-logo {
    max-width: 40px;
    display: flex;
  }

}

/* @media only screen and (max-width: 1380px) {
  .companies > img {
    width: 100px;
  }
} */

@media only screen and (max-width: 1380px) {
  .companies {
    display: flex;
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll !important;
    justify-content: flex-start;
    gap: 10px;
    max-height: 150px;
  }

  .companies img {
    /* width: 100px; */
    /* height: 50px; */
    margin-right: 35px;
  }
  .companies::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (max-width: 1420px) {
  .contact-input > input {
    font-size: 1rem;
  }
  .contact-input > textarea {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .team article {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* flex flex-col align-center  */
  }

  .team {
    padding-top: 4rem;
  }

  .team img {
    width: 18rem;
  }

  .team p {
    margin: 1rem 0 0;
  }
}

/* @media only screen and (max-width: 1024px) {
  .contact-input > textarea {
    font-size: 1.22rem;
  }
} */
@media only screen and (max-width: 450px) {
  .companies img {
    max-height: 50px;
  }
}

/* @media only screen and (min-height: 1080px) and (max-height: 1260px) {
  .mid-line {
    height: 72vh;
  }
}

@media only screen and (min-height: 1260px) and (max-height: 1520px) {
  .mid-line {
    height: 73vh;
  }
} */

.loader-wrapper {
  animation: dissapper 300ms ease-in 4.4s;
  animation-fill-mode: forwards;
  pointer-events: all;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

@keyframes dissapper {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    pointer-events: none;
  }
}

.h-screen {
  height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
}

